/* Page */
.page {
  margin-top: 80px;
  margin-bottom: 80px;
  @media (max-width: $mobile) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.page__head {
  text-align: left;
}

.page__title {
  margin-bottom: 30px;
  font-size: 50px;
  font-weight: 600;
  line-height: 55px;
}

.page__image {
  margin-bottom: 45px;
  img {
    display: block;
    margin: auto;
    object-fit: contain;
    border-radius: $border-radius;
  }
}

.page__content {
  margin-bottom: 45px;
  p {
    font-size: 1rem;
    line-height: 30px;
    color: $dark;
    img {
      margin: 30px 0;
    }
  }
  ul li,
  ol li {
    margin-bottom: 15px;
  }
}
