.contact__item {
  display: inline-block;
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }
}

.contact__link { 
  display: inline-block;
  padding: 6px;
}

.contact__icon {
  display: block;
  // note: width/height is embedded in the svg tag to avoid broken appearance when css doesn't load in time
  use {
    fill: $gray-darkish;
    transition: all 300ms ease;
  }
}

.contact__link:hover use {
  fill: $gray;
}