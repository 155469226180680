// *********
// Variables
// *********

// Font
$font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Oxygen", Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, sans-serif;
$font-serif: -apple-system-ui-serif, ui-serif, 'Georgia', serif;

// Text size
$base-text-size: 19px;
$base-text-size-mobile: 16px;
$big-text-size: 1.15rem;
$small-text-size: 0.87rem;

// Color
$primary-color: #007aff;
$secondary-color: #007aff77;
$dark: #222;
$gray: #666;
$gray-darkish: #888;
$gray-dark: #757575;
$gray-blue: #b3bbcb;
$gray-white: #f8f8f8;
$gray-lighten: #eee;
$white: #fff;

// Layout
$border-radius: 5px;
