/* Release Page */
.release__link__container { 
  margin: auto;
  margin-bottom: 1rem;
  max-width: 380px;
  text-align: center;
  a {
    .release__link { 
      display: grid;
      grid-template-columns: $big-text-size 1fr $big-text-size;
      align-items: center;
      padding: 1rem;
      font-size: $big-text-size;
      font-weight: 500;
      color: $dark;
      border: 0.1rem solid $dark;
      border-radius: $border-radius;
      @media (min-width: $mobile) {
        &:hover {
          color: $white;
          background-color: $primary-color;
          border: 0.1rem solid $white;
          .release__icon { 
            fill: $white;
          }
        }
      }
      @media (max-width: $mobile) {
        &:active {
          background-color: $gray-white;
        }
      }
    }
  }
}

.release__icon { 
  height: 22px;
  width: 22px;
}

.release__image {
  margin: auto;
  margin-bottom: 1.5rem;
  border-radius: $border-radius;
  overflow: hidden;
  width: 60vw;
  max-width: 340px;
  img {
    border-style: solid;
    border-width: 1px;
    border-radius: $border-radius;
    border-color: $gray-lighten;
  }
}

.release__info { 
  margin-bottom: 3rem;

  .release__title {
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: $big-text-size;
    font-weight: 600;
  }

  .release__artists {
    text-align: center;
    font-size: 1rem;
  }
}

.release__stream__title { 
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: $big-text-size;
    font-weight: 600;
}

.lyrics {
  text-align: center;
}

.lyrics__button {
  margin: auto;
  margin-top: 2rem;
  padding: 1rem;
  cursor: pointer;
  text-align: left;
  font-size: $big-text-size;
  font-weight: 600;
  border: none;
  box-shadow: 0px 0.1rem $dark;
  width: 100%;
  color: $dark;
  background-color: $white;
  &:after {
    content: '\002B';
    float: right;
  }

  &.active {
    &:after {
      content: '\2212';
    }
  }
}

.lyrics__content {
  padding: 0 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6;
  max-height: 0;
  width: 100%;
  text-align: left;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  &>p {
    margin-top: 1rem;
  }
}