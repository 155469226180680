/* Header */
.header {
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 30px 0;
}

.logo__link {
  font-size: 1.8rem;
  font-weight: 700;
  color: $dark;
  letter-spacing: -1px;
}

.tagline {
  padding-left: 2px;
  font-size: $big-text-size;
  font-weight: 600;
  color: $gray-dark;
  letter-spacing: -0.5px;
}

.nav__item {
  display: inline-block;
  font-size: $big-text-size;
  font-weight:600;
  margin-left: 26px;
  .nav__link {
    color: $dark;
    border-radius: 3px;
    padding: 3px 6px;
    transition: color 0ms;
    border: 2px solid $white;
    &:hover {
      border: 2px solid $primary-color;
      background-color: $primary-color;
      color: $white;
    }
  }
    .selected { 
      // option 1
      // color: $primary-color;

      // option 2
      // border-bottom: 0.1rem solid $dark;
      // border-radius: 0;
      // &:hover {
      //   border-bottom: 0 solid $dark;
      //   color: $dark;
      //   border-radius: 3px;
      //   color: $white;
      // }

      // option 3
      border: 2px solid $dark;
      &:hover {
        border: 2px solid $dark;
        background-color: $white;
        color: $dark;
      }
    }
}

// .main-nav__menu {
  // note: width/height is embedded in the svg tag to avoid broken appearance when css doesn't load in time
// }

use.main-nav__menu {
  color: $dark;
}

/* Mobile Nav */
.main-nav__open {
  position: absolute;
  top: 2.52rem;
  right: 0;
  z-index: 10;
  font-size: $big-text-size;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  @media (max-width: $mobile) {
    position: relative;
    top: 0;
    visibility: visible;
    opacity: 1;
  }
}

.main-nav__close {
  position: fixed;
  top: 2.25rem;
  right: 30px;
  @media (max-width: $mobile) {
    right: 20px;
  }
  font-size: $big-text-size;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}

@media (max-width: $mobile) {
  .main-nav__box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    background-color: $white;
    // transition: 300ms ease-in-out; 
    &.visible {
      transition: 300ms ease-in-out;
      visibility: visible;
      opacity: 1;

      .main-nav__close {
        visibility: visible;
        opacity: 1;
      }
    }

    &.hidden {
      transition: 300ms ease-in-out; 
      transition-delay: 110ms; // fixes animation glitch where previous page flashes before loading new page
      visibility: hidden;
      opacity: 0;
    }
  }

  .nav__list {
    position: absolute;
    padding: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 50vh;
    overflow: auto;
    .nav__item {
      display: block;
      margin-left: 0;
      margin-bottom: 30px;
      font-size: 27px;
      line-height: 33px;
      text-align: center;
    }
  }
}
