/* Author */
.author {
  margin: 70px 0;
  padding-bottom: 70px;
  border-bottom: 1px solid $gray-lighten;
  @media (max-width: $mobile) {
    margin: 50px 0;
    padding-bottom: 50px;
  }
}

.author__img {
  img {
    display: block;
    max-width: 160px;
    max-height: 160px;
    margin: 0 auto 70px;
    border-radius: 50%;
    background-color: $gray-white;
  }
}

.author__bio {
  max-width: 450px;
  margin: 0 auto 0px;
  font-size: $big-text-size;
  font-weight: 600;
  line-height: 2;
  text-align: center;
  color: $dark;
}

.author__contact__profile { 
  margin-bottom: 3rem;
}

.author__contact {
  text-align: center;
}

*, *::after, *::before {
  box-sizing: border-box;
}
