/* Footer */
.footer {
  margin-top: 80px;
  padding-bottom: 30px;
  border-top: 1px solid $gray-lighten;
  @media (max-width: $mobile) {
    margin-top: 50px;
  }
}

.footer__contact {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

.footer__email__signup {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
    iframe {
      max-width: 100%;
      width: 480px;
      height: 320px;
    }
}

.footer__text { 
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.5;
  color: $gray-dark; 
  a {
    text-decoration: underline;
    color: $gray-dark;
  }
}

.footer .left {
  text-align: left;
}

.footer .right { 
  text-align: right;
}
