/* Post */
.post {
  margin-top: 80px;
  margin-bottom: 80px;
  @media (max-width: $mobile) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.post__head {
  text-align: left;
}

.post__title {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 600;
}

.post__date {
  margin-bottom: 2rem;
  font-family: $font-family;
  font-size: $small-text-size;
  color: $gray-dark;
}

.post__image {
  margin-bottom: 45px;
  img {
    display: block;
    max-height: 432px;
    margin: auto;
    object-fit: contain;
    border-style: solid;
    border-radius: $border-radius;
    border-width: 1px;
    border-color: $gray-lighten;
  }
}

.post__content {
  margin-bottom: 45px;
  p {
    font-size: 1.1rem;
    line-height: 1.58; /*medium.com*/
    color: $dark;
    img {
      margin: 30px 0;
    }
  }
  ul li,
  ol li {
    margin-bottom: 15px;
  }
  img {
    margin: 30px 0;
  }
}

/* Post Navigation */
.post__navigation {
  position: relative;
  z-index: 100;
  @media (max-width: $tablet) {
    display: none;
  }
}

.next,
.prev {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  color: $dark;
  &:hover {
    .post__nav-wrapper {
      visibility: visible;
      opacity: 1;
      transition: opacity 150ms ease-out, visibility 0ms 0ms linear, transform 100ms ease-out;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }
    .next__post-icon svg,
    .prev__post-icon svg {
      opacity: 0;
      transition: transform 150ms ease-out, opacity 100ms ease-out;
    }
  }
}

.prev {
  left: 0;
  .post__nav-wrapper {
    left: 0;
    transform: translate3d(-30px,-50%,0);
    border-radius: 0 $border-radius $border-radius 0;
  }
  &:hover {
    .post__nav-wrapper {
      padding: 10px 10px 10px 0;
      transform: translate3d(0,-50%,0);
    }
    .prev__post-icon svg {
      transform: translatex(-40px);
    }
  }

  .post__nav-image {
    right: auto;
    left: 10px;
  }

  .post__nav-image ~ h2 {
    padding-left: calc(70px + 15px);
    padding-right: 0;
  }

  .post__nav-title {
    text-align: left;
    padding-left: 15px;
    padding-right: 0;
  }
}

.next {
  right: 0;
  .post__nav-wrapper {
    right: 0;
    transform: translate3d(30px,-50%,0);
    border-radius: $border-radius 0 0 $border-radius;
  }
  &:hover {
    .post__nav-wrapper {
      transform: translate3d(0,-50%,0);
    }
    .next__post-icon svg {
      transform: translatex(40px);
    }
  }

  .post__nav-image {
    right: 10px;
    left: auto;
  }

  .post__nav-image ~ h2 {
    padding-right: calc(70px + 15px);
    padding-left: 0;
  }

  .post__nav-title {
    text-align: right;
    padding-right: 15px;
    padding-left: 0;
  }
}

.next__post-icon,
.prev__post-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 0;
  line-height: 1;
  opacity: 1;
  svg {
    width: 25px;
    height: 25px;
    opacity: 1;
    transition: transform 150ms ease-out, opacity 100ms ease-out;
  }
}

.post__nav-wrapper {
  position: absolute;
  top: 50%;
  margin: 0;
  width: 260px;
  padding: 10px 0 10px 10px;
  min-height: 80px;
  visibility: hidden;
  opacity: 0;
  background-color: $white;
  transition: opacity 150ms ease-out, visibility 0ms 150ms linear, transform 100ms ease-out;
  pointer-events: auto;
  z-index: 100;
}

.post__nav-image {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  transform: translatey(-50%);
  border-radius: 3px;
  img {
    object-fit: cover;
    width: 60px;
    height: 60px;
  }
  &:after {
    content: '';
    display: table;
    padding-top: 100%;
  }
}

.post__nav-image ~ h2 {
  padding-left: calc(70px + 15px);
}

.post__nav-title {
  padding: 5px 15px 0 0;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  vertical-align: middle;
  time {
    padding-right: 5px;
    font-family: $font-family;
    font-size: 12px;
    color: $gray-dark;
  }
}

/* Post Tags */
.post-tags {
  margin-bottom: 30px;
}

.post-tags__tag {
  font-family: $font-family;
  font-size: 0.8rem;
  line-height: 24px;
  color: $gray-dark;
  &:after {
    content: ',';
    display: inline-block;
  }
  &:last-child {
    &:after {
      content: '';
      display: inline-block;
    }
  }
}

/* Post Share */
.post__share {
  margin-bottom: 60px;
}

.share__item {
  display: inline-block;
  margin-right: 10px;
}

.share__link svg {
  width: 21px;
  height: 21px;
  fill: $gray-lighten;
  transition: fill 200ms;
}

.share__link:hover {
  svg {
    fill: $dark;
  }
}

/* Disqus */
.disqus-button {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  border: none;
  outline: none;
  color: $primary-color;
  background-color: transparent;
  cursor: pointer;
}
