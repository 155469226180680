$desktop: 992px;
$tablet:  768px;
$mobile:  576px;

.super-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.container {
  max-width: 700px;
}

.container-full {
  max-width: 1100px;
}

.container, .container-full {
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  @media (max-width: $mobile) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
