.music__grid {
  max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 60px;
  @media (max-width: $mobile) {
    column-gap: 30px;
  }
}

.music__release {
  a {
    color: $dark;
  }
}

.release__image__container {
  padding-bottom: 100%;
  background: $gray-white;
  height: 0;
  margin-bottom: 1.5rem;
  border-radius: $border-radius;
  img {
    border-style: solid;
    border-width: 1px;
    border-radius: $border-radius;
    border-color: $gray-lighten;
  }
}

.music__release__image { 
  width: 100%;
  height: 100%;
}

.music__release__artists { 
  @media (max-width: $mobile) {
    display: none;
  }
}